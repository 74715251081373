'use client';

import { getProjects, signInWithGoogle } from '@/lib/auth';
import { useRouter } from 'next/navigation';
import { Button } from '@/components/ui/Button';
import Google from '@/icons/google.svg';
import { auth } from '@/lib/firebase';
import { useEffect, useMemo, useState } from 'react';
import BarLoader from 'react-spinners/BarLoader';
import { toast } from 'sonner';

export default function GoogleButton() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const router = useRouter();

    const googleColors = useMemo(
        () => ['#4285F4', '#EA4335', '#FBBC05', '#34A853'],
        [],
    );

    const [currentColor, setCurrentColor] = useState(googleColors[0]);

    useEffect(() => {
        let colorIndex = 0;
        const intervalId = setInterval(() => {
            colorIndex = (colorIndex + 1) % googleColors.length;
            setCurrentColor(googleColors[colorIndex]);
        }, 2000);

        return () => clearInterval(intervalId);
    }, [googleColors]);

    const handleSignIn = async () => {
        setIsSubmitting(true);
        const toastLogin = toast.loading('Estamos vendo se é você mesmo...');
        const isOk = await signInWithGoogle();
        if (isOk) {
            toast.success(
                'Login efetuado com sucesso! Estamos te redirecionando, por favor aguarde.',
                {
                    id: toastLogin,
                },
            );
            const userId = auth.currentUser?.uid;
            const userIdToken = await auth.currentUser?.getIdToken();
            await getProjects(userId, userIdToken);

            router.refresh();
            await router.push('/dashboard');
        } else {
            toast.error('Algo deu errado! Tente novamente.', {
                id: toastLogin,
            });
        }
        setIsSubmitting(false);
    };

    return (
        <div className="w-full">
            <Button
                disabled={isSubmitting}
                type="button"
                onClick={(e) => {
                    e.preventDefault();
                    handleSignIn();
                }}
                variant="outline"
                className="
                            h-fit w-full rounded-xl backdrop-blur-sm transition-all duration-500
                            hover:text-background active:border-primary active:ease-in disabled:opacity-100 disabled:backdrop-blur-sm sm:h-full"
            >
                <div className="flex gap-2 [&_svg]:mt-1">
                    {isSubmitting ? (
                        <BarLoader
                            color={currentColor}
                            loading={isSubmitting}
                            height="8px"
                            speedMultiplier={0.5}
                            className="my-3 !w-[45vw] rounded-full transition-all duration-500 *:transition-all *:duration-500 xl:!w-[25vw]"
                        />
                    ) : (
                        <>
                            <Google />
                            ENTRAR COM O GOOGLE
                        </>
                    )}
                </div>
            </Button>
        </div>
    );
}
